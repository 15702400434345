.tool-box{
    display: flex;
    position: fixed;
    bottom: 1vh;
    justify-content: center;
    width: 100vw;
    z-index: 1;
    gap: 1.5rem;
    background: var(--background);
    background-color:transparent;
    height: var(--navbar-height);
    padding: 0px 1rem;
    align-items: center;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, .1);
  
}

.tool-box-items {
    display: flex;
    position: fixed;
    bottom: 1vh;
    justify-content: center;
    width: auto;
    z-index: 2;
}

.tool-box-item {
  margin: 5px;
}



.tool-box {
  z-index: 1;
  background: var(--background);
  height: var(--navbar-height);
  background-color: #0000;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100vw;
  padding: 0 1rem;
  display: flex;
  position: fixed;
  bottom: 1vh;
  box-shadow: 0 1px #0000001a;
}

.tool-box-items {
  z-index: 2;
  justify-content: center;
  width: auto;
  display: flex;
  position: fixed;
  bottom: 1vh;
}

.tool-box-item {
  margin: 5px;
}
/*# sourceMappingURL=index.3e82a5cb.css.map */
